<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-select
          :items="cmd_list"
          v-model="cmd"
          item-value="id"
          item-text="designation"
          label="Commande"
          dense
          return-object
          @change="select_cmd"
        >
        </v-select>
        <div v-if="enabled_realisation">
          Client: &nbsp; &nbsp; &nbsp; {{ cmd.client }}<br />
          Lineaire: &nbsp; {{ numberWithSpace(decimalDigits(cmd.lineaire))
          }}<br />
          Date: &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; {{ datefr(cmd.date) }}
        </div>
      </v-col>
      <v-col cols="auto" v-if="edit">
        <v-row v-if="enabled_realisation">
          <v-btn color="blue darken-1" @click.stop="edit_cmd" class="ma-1">
            Editer Commande
          </v-btn>

          <v-btn color="blue darken-1" @click.stop="PrevisionOpen" class="ma-1">
            Prevision Commande
          </v-btn>

          <v-btn color="blue darken-1" @click.stop="add_cmd" class="ma-1">
            Ajouter Commande
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" v-if="enabled_realisation"
        ><br />
        <v-row>
          <v-col cols="12" sm="2" md="2">
            <v-select
              :items="['Ghardaia', 'Annaba', 'Oran']"
              v-model="unite"
              label="Unite"
              outlined
              dense
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <datepicker
              label="Select Date"
              :edit="true"
              v-model="selected_date"
              :key="cs"
              :clearable="false"
              :outlined="true"
            ></datepicker>
          </v-col>

          <v-col cols="12" sm="2" md="2">
            <v-btn
              color="blue darken-1"
              @click.stop="realisation_get"
              :disabled="!selected_date || !unite"
              class="ma-1"
            >
              OK
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="2" md="2" v-if="edit">
            <v-btn
              color="blue darken-1"
              @click.stop="realisation_add"
              :disabled="!selected_date || !unite"
              class="ma-1"
            >
              Editer
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <listitems
              :title="'Production'"
              :list="dcmd_list.filter((elm) => elm.dcmd_id <= 6)"
              :toolbar="false"
              :headers="realisations_headers"
              :key="klist"
              :showstd="false"
              :add="false"
              :del="false"
              :hdf="true"
              :disablesort="true"
              :total_items="15"
            >
            </listitems>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <listitems
              :title="'Expedition'"
              :list="dcmd_list.filter((elm) => elm.dcmd_id == 7)"
              :toolbar="false"
              :headers="realisations_headers"
              :key="klist"
              :showstd="false"
              :add="false"
              :del="false"
              :hdf="true"
              :disablesort="true"
              :total_items="15"
            >
            </listitems>
          </v-col>
          <v-col cols="12" sm="8" md="8">
            <listitems
              :title="'Situation Acier'"
              :list="dcmd_list.filter((elm) => elm.dcmd_id > 7)"
              :toolbar="false"
              :headers="metal_headers"
              :key="klist"
              :showstd="false"
              :add="false"
              :del="false"
              :hdf="true"
              :disablesort="true"
              :total_items="15"
            >
            </listitems>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Cmd operation -->
    <v-form ref="formOp" lazy-validation>
      <v-dialog
        v-model="dialog"
        max-width="600px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
        key="kform"
      >
        <v-card>
          <v-card-subtitle
            ><span class="headline">Edition commande</span></v-card-subtitle
          >
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="cmd_new.designation"
                outlined
                dense
                label="Designation"
                :rules="[(v) => !!v || 'Designation Obligatoire']"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                clearable
                v-model="cmd_new.client_id"
                :items="clients_list"
                label="Client"
                item-text="nom"
                item-value="id"
                :rules="[(v) => !!v || 'Client Obligatoire']"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                type="number"
                dense
                v-model.number="cmd_new.lineaire"
                :label="'Lineaire'"
                hide-spin-buttons
                :rules="[(v) => v != null || 'Lineaire Obligatoire']"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ma-1" color="blue darken-1" @click.stop="save">
              Save
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" class="ma-1">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <!-- Previsions operation -->
    <v-form ref="formPrev" lazy-validation>
      <v-dialog
        v-model="prevdialog"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
        key="kform"
      >
        <v-card>
          <v-card-subtitle
            ><span class="headline">Previsions commande</span></v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="9" sm="4" md="4">
                <v-autocomplete
                  :items="cmd.dcmd"
                  v-model="prevision.dcmd_id"
                  item-value="id"
                  item-text="product"
                  label="Designation"
                  :rules="[(v) => !!v || 'Designation Obligatoire']"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="9" sm="2" md="2">
                <v-autocomplete
                  :items="['Ghardaia', 'Annaba', 'Oran']"
                  v-model="prevision.unite"
                  label="Unite"
                  :rules="[(v) => !!v || 'Unite Obligatoire']"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="9" sm="1" md="1">
                <v-autocomplete
                  :items="year_list"
                  v-model="prevision.annee"
                  label="Annee"
                  :rules="[(v) => !!v || 'Annee Obligatoire']"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="2" sm="1" md="1">
                <v-autocomplete
                  :items="month_list"
                  v-model="prevision.mois"
                  label="Mois"
                  :rules="[(v) => !!v || 'Mois Obligatoire']"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="2" sm="1" md="1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="prevision.lineaire"
                  label="Quantite"
                  :rules="[(v) => !!v || 'Quantite Obligatoire']"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click.stop="prevision_save">
                  {{ prevision_operation }}
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="blue darken-1" @click.stop="prevision_cancel">
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="previsions_list"
                :title="'Previsions'"
                :headers="previsions_headers"
                :showstd="false"
                :showedit="false"
                :master="false"
                :key="p_key"
                :ipg="-1"
                :hdf="true"
                :add="true"
                :del="false"
                :laoding="loading"
                @open="previson_add"
                @col_btn1_click="prevision_edit"
                @col_btn2_click="prevision_delete"
              >
              </listitems>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click.stop="PrevisionClose"
              class="ma-1"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <!-- Realisation operation -->
    <v-form ref="formReal" lazy-validation>
      <v-dialog
        v-model="realdialog"
        max-width="1200px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
        :key="kreal"
      >
        <v-card>
          <v-card-subtitle
            ><span class="headline">Realisation commande</span></v-card-subtitle
          >
          <v-card-text>
            <v-row dense>
              <v-col
                dense
                cols="12"
                sm="6"
                md="6"
                v-for="item in cmd.dcmd"
                :key="item.id"
              >
                <v-row dense no-gutters class="rowh2">
                  <v-col dense cols="12" sm="4" md="4">
                    <span dense> {{ item.product }}</span>
                  </v-col>
                  <v-col dense cols="12" sm="4" md="4">
                    <v-text-field
                      :ref="'T;' + item.id"
                      :value="getValue('t', item.id)"
                      autocomplete="off"
                      type="number"
                      outlined
                      dense
                      :label="item.id <= 7 ? 'Tubes' : 'Bobines'"
                      :rules="[
                        (v) =>
                          !!v ||
                          'Nombre de ' +
                            (item.id <= 7 ? 'Tubes' : 'Bobines') +
                            ' Obligatoire',
                      ]"
                      class="mx-2"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col dense cols="12" sm="4" md="4">
                    <v-text-field
                      :ref="'L;' + item.id"
                      :value="getValue('l', item.id)"
                      autocomplete="off"
                      type="number"
                      outlined
                      dense
                      :label="item.id <= 7 ? 'Lineaire' : 'Poids'"
                      :rules="[(v) => !!v || 'Quantite Obligatoire']"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              @click.stop="realisation_save"
              class="ma-1"
            >
              Enregistrer
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click.stop="realisation_cancel"
              class="ma-1"
            >
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>
<script>
import DATA from "../graphql/PProduit/pproduit.gql";
import ADD_CMD from "../graphql/PProduit/AddCommande.gql";
import ADD_REALISATION from "../graphql/PProduit/AddRealisation.gql";
import Del_Cmd from "../graphql/PProduit/DeleteCommande.gql";
import PREVISONS from "../graphql/PProduit/Previsons.gql";
//import Realisation from "../graphql/PProduit/Realisations.gql";
import CREATEPREVISONS from "../graphql/PProduit/CreatePrevisions.gql";
import UPDATEPREVISONS from "../graphql/PProduit/UpdatePrevisions.gql";
import DELETEPREVISONS from "../graphql/PProduit/DeletePrevisions.gql";
import REALISATION from "../graphql/PProduit/Realisations.gql";
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
  },
  props: {},
  data: () => ({
    load: false,
    loading: false,
    edit: true,
    selected_date: null,
    enabled_realisation: false,
    dialog: false,
    prevision_operation: "Ajouter",
    prevdialog: false,
    realdialog: false,
    kreal: 3000,
    kcmd: 200,
    kform: 1000,
    p_key: 2000,
    cs: 10000,
    month_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    year_list: [],
    cmd_list: [],
    clients_list: [],
    prevision: {
      id: null,
      dcmd_id: null,
      nbt: 0,
      lineaire: 0,
      produit: null,
    },
    dcmd_list: [],
    klist: 20000,
    unite: null,
    cmd: { dcmd: [] },
    cmd_new: {},
    previsions_list: [],
    cmdheaders: [
      {
        text: "Designation",
        value: "designation",
        selected: true,
        edit: true,
        type: "text",
        slot: "href",
        rules: [(v) => !!v || "Designation   required"],
        sm: 12,
        md: 12,
      },
      {
        text: "Client",
        value: "client",
        selected: true,
        edit: false,
        type: "text",
      },
      {
        text: "Lineaire",
        value: "lineaire",
        selected: true,
        type: "text",
      },
      {
        text: "Date",
        value: "date",
        selected: true,
        type: "text",
      },
      {
        text: "Previsons",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        valeur: 0,
        sortable: false,
      },
    ],
    previsions_headers: [
      {
        text: "Designation",
        value: "produit",
        selected: true,
      },
      {
        text: "Unite",
        value: "unite",
        selected: true,
      },
      {
        text: "Annee",
        value: "annee",
        selected: true,
      },

      {
        text: "Mois",
        value: "mois",
        selected: true,
      },
      {
        text: "Nbre T",
        value: "nbt",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "Lineaire",
        value: "lineaire",
        selected: true,
      },
      {
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        sortable: false,
      },
      {
        text: "Delete",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-delete",
        width: "10",
        sortable: false,
      },
    ],

    realisations_headers: [
      {
        text: "Designation",
        value: "produit",
        selected: true,
      },
      {
        text: "Nb Tube Jour",
        value: "nbt",
        align: "right",
        selected: true,
        slot: "cur",
      },
      {
        text: "Lineaire Jour",
        value: "lineaire",
        align: "right",
        selected: true,
        slot: "cur",
      },

      {
        text: "Nb Tube Mois",
        value: "mnbt",
        selected: true,
        slot: "cur",
      },
      {
        text: "Lineaire Mois",
        value: "mlineaire",
        selected: true,
        slot: "cur",
      },
      {
        text: "Taux Real. Mois",
        value: "mtrealise",
        selected: true,
        slot: "progress",
      },
      {
        text: "Reste Real. Mois",
        value: "mrrealise",
        selected: true,
        slot: "cur",
      },
      {
        text: "Cumul Tube Real.",
        value: "cnbt",
        selected: true,
        slot: "cur",
      },
      {
        text: "Cumul Lineiare  Real.",
        value: "clineaire",
        selected: true,
        slot: "cur",
      },
      {
        text: "Taux Real.",
        value: "taux",
        selected: true,
        slot: "progress",
      },
      {
        text: "Reste  Real.",
        value: "reste",
        selected: true,
        slot: "cur",
      },
      {
        text: "Encours ML",
        value: "encours",
        selected: true,
        slot: "cur",
      },
    ],

    metal_headers: [
      {
        text: "Designation",
        value: "produit",
        selected: true,
      },
      {
        text: "Nb Bobines Jour",
        value: "nbt",
        align: "right",
        selected: true,
        slot: "cur",
      },
      {
        text: "Poids (t) Jour",
        value: "lineaire",
        align: "right",
        selected: true,
        slot: "cur",
      },

      {
        text: "Nb Bobines Mois",
        value: "mnbt",
        selected: true,
        slot: "cur",
      },
      {
        text: "Poids (t) Mois",
        value: "mlineaire",
        selected: true,
        slot: "cur",
      },

      {
        text: "Nb Bobines Cumul.",
        value: "cnbt",
        selected: true,
        slot: "cur",
      },
      {
        text: "Poids (t) Cumul.",
        value: "clineaire",
        selected: true,
        slot: "cur",
      },
      {
        text: "Taux Real.",
        value: "taux",
        selected: true,
        slot: "progress",
      },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  async created() {
    let y = new Date().getFullYear();
    for (let i = y - 1; i <= y + 1; i++) this.year_list.push(i);
    this.selected_date = this.$store.state.today;
    this.cs++;
    this.unite = "Ghardaia";
    this.edit =
      this.$store.state.auth.includes("02110") || this.$store.state.isadmin;
    this.getdata();
  },
  methods: {
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    decimalDigits: function (value, n = 2) {
      return typeof value == "number" ? value.toFixed(n) : 0;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },

    getValue(item, id) {
      let value = 0;
      if (this.dcmd_list)
        if (this.dcmd_list.length > 0) {
          let k = this.dcmd_list.findIndex((x) => x.dcmd_id == id);
          if (k >= 0) {
            if (item == "t") value = this.dcmd_list[k].nbt;
            else if (item == "l") value = this.dcmd_list[k].lineaire;
          }
          //  this.kreal++;
        }
      return value;
    },
    realisation_cancel() {
      this.realdialog = false;
    },
    realisation_save() {
      for (let value of Object.entries(this.$refs)) {
        if (value != "formOp" && value != "formPrev" && value != "formReal") {
          let s = value[0].toString().split(";");
          if (s.length > 1) {
            if (this.dcmd_list.filter((x) => x.dcmd_id == s[1]).length <= 0) {
              let k = {
                id: null,
                dcmd_id: s[1],
                unite: this.unite,
                date: this.selected_date,
              };
              if (s[0] == "T") k.nbt = parseFloat(value[1][0].lazyValue);
              else if (s[0] == "L") k.lineaire = value[1][0].lazyValue;

              this.dcmd_list.push(k);
            } else {
              let k = this.dcmd_list.findIndex((x) => x.dcmd_id == s[1]);
              if (s[0] == "T")
                this.dcmd_list[k].nbt = parseFloat(value[1][0].lazyValue);
              else if (s[0] == "L")
                this.dcmd_list[k].lineaire = value[1][0].lazyValue;
            }
            s;
          }
        }
      }
      let list = [];
      this.dcmd_list.forEach((x) =>
        list.push({
          id: x.id,
          unite: x.unite,
          date: x.date,
          lineaire: x.lineaire,
          dcmd_id: x.dcmd_id,
          nbt: x.nbt,
        })
      );
      this.save_realisation(list);
      this.realdialog = false;
    },
    async realisation_get() {
      this.dcmd_list = [];
      let v = {
        cmd_id: parseInt(this.cmd.id),
        date: this.selected_date,
        unite: this.unite,
      };
      let r = await this.requette(REALISATION, v);
      if (r) this.dcmd_list = r.get_realisations;

      this.loading = true;

      this.klist++;
      this.loading = false;
    },
    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async realisation_add() {
      await this.realisation_get();
      this.realdialog = true;
    },
    async prevision_save() {
      let ctl = true;
      if (this.previsions_list.length > 0)
        if (
          this.previsions_list.filter(
            (x) =>
              x.annee == this.prevision.annee &&
              x.mois == this.prevision.mois &&
              x.unite == this.prevision.unite
          ) > 0
        )
          ctl = false;
      if (ctl)
        if (this.$refs.formPrev.validate()) {
          let v = {
            id: this.prevision.id,
            dcmd_id: this.prevision.dcmd_id,
            annee: this.prevision.annee,
            mois: this.prevision.mois,
            nbt: this.prevision.nbt,
            unite: this.prevision.unite,
            lineaire: this.prevision.lineaire,
          };
          if (v.id > 0) {
            await this.maj(UPDATEPREVISONS, {
              Prevision: v,
            });
          } else {
            (v.id = null),
              await this.maj(CREATEPREVISONS, {
                Prevision: v,
              });
          }
          this.previson_add();
        }
    },
    prevision_cancel() {
      this.$refs.formPrev.resetValidation();
      this.prevision.id = 0;
      this.prevision.dcmd_id = null;
      this.prevision.annee = null;
      this.prevision.unite = null;
      this.prevision.nbt = 0;
      this.prevision.mois = null;
      this.prevision.lineaire = 0;
      this.prevision_operation = "Ajouter";
    },
    prevision_edit(item) {
      this.prevision = Object.assign({}, item);
      this.prevision_operation = "Modifier";
    },
    async prevision_delete() {
      await this.maj(DELETEPREVISONS, {
        id: this.prevision.id,
      });
    },
    previson_add() {
      this.$refs.formPrev.resetValidation();
      this.prevision.id = 0;
      this.prevision.nbt = 0;
      this.prevision.mois = null;
      this.prevision.lineaire = 0;
      this.prevision_operation = "Ajouter";
    },
    async PrevisionOpen() {
      this.previsions_list = [];
      let v = {
        cmd_id: parseInt(this.cmd.id),
      };
      let r = await this.requette(PREVISONS, v);
      if (r) this.previsions_list = r.get_previsions;
      this.p_key++;
      this.prevdialog = true;
    },
    PrevisionClose() {
      this.prevdialog = false;
    },
    async getdata() {
      let r = await this.requette(DATA);
      if (r) {
        this.cmd_list = r.p_cmd_list;
        this.clients_list = r.tiers_clients;
      }
      this.kcmd++;
      if (this.cmd_list.length > 0) {
        this.cmd = this.cmd_list[0];
        this.select_cmd(this.cmd);
      }
    },
    select_cmd(item) {
      this.dcmd_list = [];
      this.cmd = item;
      this.cmd_new = item;
      this.enabled_realisation = true;
      this.realisation_get();
    },
    add_cmd() {
      this.$refs.formOp.resetValidation();
      this.cmd_new = {
        id: null,
        designation: "",
        client_id: null,
        lineaire: 0,
      };
      this.kform++;
      this.dialog = true;
    },
    edit_cmd() {
      this.$refs.formOp.resetValidation();
      this.kform++;
      this.dialog = true;
    },
    async save() {
      if (this.$refs.formOp.validate()) {
        let v = [];
        v.push({
          id: this.cmd_new.id,
          designation: this.cmd_new.designation,
          client_id: this.cmd_new.client_id,
          lineaire: this.cmd_new.lineaire,
        });
        await this.maj(ADD_CMD, {
          cmd: v,
        });
      }
    },
    async save_realisation(list) {
      await this.maj(ADD_REALISATION, {
        cmd: list,
      });
    },
    close() {
      this.dialog = false;
    },
  },
  computed: {
    Qdelete() {
      return Del_Cmd;
    },
  },
};
</script>
<style>
.rowh2 {
  width: 100% !important;
  height: 40px !important;
  margin-bottom: 8px !important;
  margin-right: 8px !important;
}
</style>
